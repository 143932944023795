import React, {useState} from "react";
import "./contactform.css";
import Mail from "../../assets/Mail.svg";
import Phone from "../../assets/Phone.svg";
import Arrow from "../../assets/Arrow1.svg"
import axios from "axios";
import {stringify} from "qs";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3"

export default function Contactform() {

    const [submitStatus, setSubmitStatus] = useState(false);
    const [getError, setError] = useState(false);

    const [token, setToken] = useState();

    const submitData = (e) => {

        e.preventDefault();

        let post = [];

        e.target.querySelectorAll(`input,textarea`).forEach(item => {

            if (item.value == "") {
                setError(true);
                return;
            }

            post[item.name] = item.value;

            setError(false);

        })

        if (!getError && post) {

            post["token"] = token;

            axios.post(`https://djpiotrszymanski.pl/api/`, stringify(post))
                .then(() => {
                    setSubmitStatus(true)
                })
                .catch(() => {
                    setSubmitStatus(false)
                })
        }

    }

    return (
        <div className="from-wrapper">
            <div className="form-title">
                <h2>Masz pytania?</h2>
                <h3 style={{color: "#fff"}} className="line-before">skontaktuj się z nami</h3>
            </div>
            <div className="contact-data">
                <div className='contact-data-name'>
                    <p>Piotr Szymański</p>
                </div>
                <div className="contsct-data-icons">
                    <Mail/> <Phone/>
                </div>
                <div className="color-second contact-data-nums">
                    <div>Tel.: <a href={"tel:+48792530430"} className={"color-second"}>+48 792 530 430</a></div>
                    <div>E-mail: <a href={"mailto:hello@djpiotrszymanski.pl"}
                                    className={"color-second"}>hello@djpiotrszymanski.pl</a></div>
                </div>
            </div>
            <GoogleReCaptchaProvider reCaptchaKey="6Lfp2QofAAAAAFvOvI552ulSuxfo9sqbGMD8Bima">
                {
                    !submitStatus && <form className="form" method={"POST"} onSubmit={submitData}>
                        <div className="form-item">
                            <input id="email" name="email" type="email" placeholder="E-mail" required/>
                        </div>
                        <div className="form-item">
                            <input id="phone" name="phone" type="phone" placeholder="Numer telefonu" defaultValue={"+48"}
                                   maxLength={12} required/>
                        </div>
                        <div className="form-item">
                            <input id="date" name="date" type="date" placeholder="Data w formacie dd-mm-rrrr" required/>
                        </div>
                        <div className="form-item">
                            <input id="place" name="place" type="text" placeholder="Miejsce" required/>
                        </div>
                        <div className="form-item">
                            <input id="guests" name="guests" type="number" placeholder="Liczba gości" required min={1}/>
                        </div>
                        <div className="form-item">
                    <textarea id="message" name="message" type="text" placeholder="Wiadomości" rows="4" required
                              minLength={15}/>
                        </div>
                        <div className="submit">
                            <input id="submit" type="submit" value="wyślij"/><Arrow/>
                        </div>
                        <GoogleReCaptcha
                            onVerify={token => {
                                setToken(token)
                            }}
                        />
                    </form>
                }
            </GoogleReCaptchaProvider>
            {
                submitStatus && <div>Wiadomość została wysłana, postaram się szybko odpowiedzieć.</div>
            }
        </div>
    );
}
